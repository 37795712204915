<template>
    <div class="home__newstatistics rounded-2xl">
        <div class="home__newstatistics__header rounded-t-2xl">
            <strong class="title">Mon développement de compétences</strong>
        </div>
        <div class="home__newstatistics__content rounded-b-2xl">
            <div class="home__newstatistics__content__details">
                <div class="home__newstatistics__content__details__content">
                    <div class="row">
                        <strong>{{ UserStore.timeSpent }}</strong>
                        <p>
                            <span>de formation réalisée</span>
                        </p>
                    </div>
                    <div class="row">
                        <div class="total">
                            <strong>{{ UserStore.lockedTrophiesCount }}</strong>
                            <img src="@/assets/icons/icon-badges.png" alt="">
                        </div>
                        <p v-if="UserStore.lockedTrophiesCount > 1">
                            <span>Badges</span>
                            <br>
                            <small>obtenus</small>
                        </p>
                        <p v-else>
                            <span>Badge</span>
                            <br>
                            <small>obtenu</small>
                        </p>
                    </div>
                    <div class="row">
                        <div class="total">
                            <strong>{{ UserStore.documentsCount }}</strong>
                            <img src="@/assets/icons/icon-documents.png" alt="">
                        </div>
                        <p v-if="UserStore.documentsCount > 1">
                            <span>Documents</span>
                            <br>
                            <small>disponibles</small>
                        </p>
                        <p v-else>
                            <span>Document</span>
                            <br>
                            <small>disponible</small>
                        </p>
                    </div>
                </div>
            </div>
            <div class="home__newstatistics__charts">
                <div class="home__newstatistics__chart home__newstatistics__chart__dividers">
                    <strong class="title">Savoir être</strong>
                    <div class="home__newstatistics__chart__radar">
                        <canvas ref="basicChart" v-if="displayChart('basic')"></canvas>
                        <img src="@/assets/blankchart-basic.png" alt="" v-else>
                    </div>
                </div>
                <div class="home__newstatistics__chart">
                    <strong class="title">Bonnes pratiques</strong>
                    <div class="home__newstatistics__chart__radar">
                        <canvas ref="techChart" v-if="displayChart('tech')"></canvas>
                        <img src="@/assets/blankchart-tech.png" alt="" v-else>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useUserStore } from "@/store/UserStore"
import UserMixin from '@/mixins/userMixin'
import Chart from 'chart.js/auto'

export default {
  mixins: [UserMixin,],
  data () {
    const UserStore = useUserStore();
    return {
      UserStore: UserStore,
      options:{
        legend: {
            display: false
        },
        tooltips: {enabled: false},
        hover: {mode: null},
        animation: {
            duration: 0.5,
        },
        elements: {
            pointLabels: {
                    display: false,
                },
            line: {
                borderWidth: 3
            }
        },
        responsive: false,
        scale: {
            ticks: {
                callback: function() {return ""}
            },
        },
        scales: {
            r: {
                suggestedMin: 0,
                suggestedMax: 100,
                ticks: {
                    display: false,
                },
                grid: {
                    display: true,
                    color: 'rgb(95, 53, 151, 0.6)',
                    lineWidth: function(context) {
                        return 1;
                    }
                },
                angleLines: {
                    display: false
                },
                pointLabels: {
                    font: {
                        size: 12
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false,
            },
        }
      },
    }
  },
  mounted() {
    this.renderChart('basicChart')
    this.renderChart('techChart')
  },
  methods: {
    displayChart(type) {
        const chart = type === 'basic' ? 'basicStats' : 'techStats'
        const stats = this.UserStore.statistics[chart]

        const data = stats.filter((stat) => stat.value > 0)

        return data && data.length > 0
    },
    renderChart(id) {
        const chart = id === 'basicChart' ? 'basicStats' : 'techStats'
        const stats = this.UserStore.statistics[chart]

        if (!this.displayChart(id)) {
            return
        }

        const labels = stats.map(stat => {
            return stat.name
        })

        const data = stats.map(stat => {
            return stat.value
        })

        let chartData = {
            labels: labels,
        }
        
        let ctx

        switch (id) {
            case 'basicChart':
                ctx = this.$refs.basicChart
                
                chartData.datasets = [
                    {
                        fill: true,
                        data: data,
                        backgroundColor: 'rgba(183, 68, 252, 0.66)',
                        borderColor: 'rgb(169, 22, 255, 1)'
                    }
                ]
                break
            case 'techChart':
                ctx = this.$refs.techChart
                
                chartData.datasets = [
                    {
                        fill: true,
                        data: data,
                        backgroundColor: 'rgba(55, 112, 246, 0.66)',
                        borderColor: 'rgba(15, 84, 250, 1)'
                    }
                ]
                break
        }

        new Chart(ctx, {
            type: 'radar',
            data: chartData,
            options: this.options
        })
    }
  }
}
</script>