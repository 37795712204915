<template>
    <div class="courses">
        <div class="courses__content rounded-2xl" :class="blocLabelClass">
            <div class="courses__bloc__label">
                <span>{{ blocLabel }}</span>
            </div>
            <div class="courses__content__bloc">
                <div class="courses__locked rounded-2xl" v-if="item.locked">
                  <img src="@/assets/icons/icon-lock.svg" alt="">
                </div>
                <div class="courses__locked p-4  flex justify-center items-center rounded-2xl" v-if="item.locked">
                    <img src="@/assets/icons/icon-lock.svg" alt="">
                </div>
                <div class="courses__header relative px-4 rounded-t-2xl" :style="{'background-image': 'url('+item.background+')'}">
                    <div class="courses__header__content pt-4 px-12">
                        <h2>{{ item.name }}</h2>
                    </div>
                </div>
                <div class="courses__footer flex items-center justify-between px-4 rounded-b-2xl">
                    <button @click="launcher(item.ref)" class="btn rounded-full rounded-full py-2 px-4 flex items-center inline-flex"
                    :class="btnClass">
                        {{ ctaLabel }}
                    </button>
                    <div class="flex items-center justify-between courses__duration" v-if="duration">
                        <img src="@/assets/icons/icon-module-time.svg" class="mr-2"/>
                        {{  duration  }}
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import AuthMixin from '@/mixins/authMixin'
  import UIMixin from '@/mixins/uiMixin'
  
  export default {
    mixins: [AuthMixin, UIMixin],
    props: {
      item: Object,
      themeStore: Object
    },
    data() {
      return {
        fetchingUrl: false,
        current: true,
        options: {
          cutoutPercentage: 85,
          responsive: true,
          maintainAspectRatio: false,
          animation: {
            animateRotate: false
          },
          legend: {
            display: false
          },
          hover: {mode: null},
          tooltips: {
            enabled: false
          },
        }
      }
    },
    computed: {
      isCurrent() {
        return this.current
      },
      blocLabel() {
        switch (this.item.state) {
            case 0: // Not started
                return null
            case 1: // In progress
            case 2: // To continue
                return 'Reprendre là où vous en étiez'
            case 3: // Failed
                return 'Ce module n\'est pas validé'
            case 4: // Successed
                return 'Vous avez validé ce module'
        }
      },
      blocLabelClass() {
        switch (this.item.state) {
            case 0: // Not started
                return null
            case 1: // In progress
            case 2: // To continue
                return 'courses__content__tocontinue'
            case 3: // Failed
                return 'courses__content__toredo'
            case 4: // Successed
                return 'courses__content__success'
        }
      },
      btnClass () {
        switch (this.item.state) {
            case 0: // Not started
                return 'btn-start'
            case 1: // In progress
            case 2: // To continue
                return 'btn-continue'
            case 3: // Failed
                return 'btn-retry'
            case 4: // Successed
                return 'btn-success'
        }
      },
      ctaLabel () {
        switch (this.item.state) {
            case 0: // Not started
                return 'Commencer'
            case 1: // In progress
            case 2: // To continue
                return 'Reprendre'
            case 3: // Failed
            case 4: // Successed
                return 'Recommencer'
        }
      },
      duration () {
        let duration = this.item.duration
  
        if (!duration) {
          return
        }
  
        const minutes = Math.floor(duration / 60)
        const seconds = duration - minutes * 60
        let durationTxt = ''
  
        if (minutes) {
          durationTxt = minutes + ' min'
        }
  
        if (seconds) {
          durationTxt = durationTxt ? durationTxt + ' ' + seconds : seconds + ' sec'
        }
  
        return durationTxt
      }
    },
    methods: {
        launcher(scormCloudId) {
            if (this.fetchingUrl || this.item.locked) {
                return false
            }
            
            const client = this.themeStore.client
            const url = process.env.VUE_APP_BASE_URL + '/data/Scorm/' + scormCloudId
            + '/link?client=' + client
            const accessToken = this.$cookies.get('access_token')
            this.fetchingUrl = true

            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: 'Bearer ' + accessToken
            }

            this.$http.get(url, { headers: headers }).then(
                res => {
                    const url = res.data

                    if (this.deviceType == 'desktop') {
                        window.open(url)
                    } else {
                        window.location.href = url
                    }

                    this.fetchingUrl = false
                },
                error => {
                    this.fetchingUrl = false
                    
                    if (parseInt(error.response.status) == 401) {
                        this.refreshToken(() => {
                            this.launcher(scormCloudId)
                        })
                        
                        return
                    }
                }
            )
        }
    }
  }
  </script>