<template>
  <Layout>
    <div class="home" v-if="['aapp','ubi','aap','hygie-recrutement'].includes(ThemeStore.client)">
      <div class="home__header pb-10" :style="layoutBackground">
        <div class="container mx-auto">
          <Header/>
          <div class="px-6 md:px-11 mt-5" v-if="currentParcours">
            <div class="home__header__content mx-auto pt-10 pb-2 md:px-28 rounded-2xl">
              <div class="md:grid grid-cols-2 md:divide-x-2">
                <div class="px-4">
                  <PlayerWidget/>
                </div>
                <div class="px-4">
                  <ProgressionWidget/>
                </div>
              </div>
              <div class="home__header__current_parcours text-center mt-5 pt-5">
                <img :src="currentParcours.icon" alt="" class="mx-auto">
                <h2 class="mt-2 md:mt-0">{{ currentParcours.name }}</h2>
              </div>
            </div>
            <div class="home__parcours mt-2">
              <CurrentParcours :item="currentParcours"/>
            </div>
          </div>
        </div>
      </div>
      <div class="clear-both" v-if="statisticsActivated">
        <Statistics/>
      </div>
    </div>
    <div class="home" v-else>
      <div class="home__header" :style="layoutBackground">
        <div class="home__header__white home__header__elliptic">
          <div class="container mx-auto">
            <NewHeader/>
          </div>
        </div>
        <CoursesFilter @type-updated="filterCourses"/>
        <!-- <div class="container mx-auto mt-6" v-if="filter == 'tech'">
          <ThematicsFilter @filters-updated="setThematicRefs"/>
        </div> -->
        <div v-if="filter == 'tech'">
          <module-group-list :filter="filter" @group-updated="getCoursesGroup" v-if="!selectedGroup"/>
          <module-list v-else :filter="filter" :group="group" @back="selectedGroup = false"/>
        </div>
        <course-list v-else :filter="filter"/>
        <div class="container mx-auto" v-if="['esse', 'tech'].includes(filter)">
          <NewStatistics/>
        </div>
        <new-footer class="mt-10"/>
      </div>
    </div>
  </Layout>
</template>

<script>
// @ is an alias to /src
import Layout from '@/components/Layout.vue'
import Header from '@/components/Partials/Header.vue'
import NewHeader from '@/components/Partials/NewHeader.vue'
import NewFooter from '@/components/Partials/NewFooter.vue'
import CoursesFilter from '@/components/Partials/CoursesFilter.vue'
import CourseList from '@/components/Courses/Index.vue'
import ModuleGroupList from '@/components/Modules/Groups/Index.vue'
import ModuleList from '@/components/Modules/Index.vue'
import ThematicsFilter from '@/components/Partials/ThematicsFilter.vue'
import PlayerWidget from '@/components/Home/PlayerWidget.vue'
import ProgressionWidget from '@/components/Home/ProgressionWidget.vue'
import CurrentParcours from '@/components/Parcours/Current';
import Statistics from '@/components/Home/Statistics.vue'
import NewStatistics from '@/components/Home/NewStatistics.vue'
import { useThemeStore } from "@/store/ThemeStore";

import UserMixin from '@/mixins/userMixin'
import UIMixin from '@/mixins/uiMixin'
import AuthMixin from '@/mixins/authMixin'

export default {
  mixins: [AuthMixin, UserMixin, UIMixin],
  name: 'Home',
  components: {
    Layout,
    Header,
    NewHeader,
    NewFooter,
    CoursesFilter,
    CourseList,
    ModuleGroupList,
    ModuleList,
    PlayerWidget,
    ProgressionWidget,
    CurrentParcours,
    Statistics,
    NewStatistics,
    ThematicsFilter,
  },
  data () {
    const ThemeStore = useThemeStore();
    return {
      ThemeStore: ThemeStore,
      uniqueParcours: false,
      selectedGroup: false,
      currentParcours: {},
      group: {},
      courses: [],
      coursesCount: 0,
      coursesPerPage: 0,
      currentPage: 0,
      pagesCount: 0,
      forumCategoryId: null,
      listCarousel: true,
      modules: [],
      filter: 'esse',
      thematicRefs: null,
    }
  },
  computed: {
    statisticsActivated () {
      return process.env.VUE_APP_ACTIVATE_STATISTICS == 1
    },
    coursesList() {
      return this.courses.filter(elt => {
          const expFolder = elt.ref.split('-')

          return expFolder[0] == this.filter
      })
    }
  },
  mounted() {
    switch (this.ThemeStore.client) {
      case 'aap':
      case 'aapp':
      case 'hygie-recrutement':
      case 'ubi':
        this.fetchParcours()
        break
    }
    
  },
  methods: {
    getCoursesGroup(group) {
      this.group = {
        ref: group.ref,
        name: group.name
      }

      this.selectedGroup = true
    },
    getListMode (carousel) {
      this.listCarousel = carousel
    },
    getCategoryId (id) {
      this.forumCategoryId = id
    },
    getModules (modules) {
      this.modules = modules
    },
    setThematicRefs (thematicRefs) {
      this.thematicRefs = thematicRefs
      this.fetch()
    },
    filterCourses(type) {
      this.filter = type

      this.selectedGroup = false
    },
    fetchParcours(page) {
        const url = process.env.VUE_APP_BASE_URL + '/data/Scorm/Courses'
        const accessToken = this.$cookies.get('access_token')
        this.fetching = true

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: 'Bearer ' + accessToken
        }

        this.$http.get(url, { headers: headers, params: {
        client: this.ThemeStore.client,
        page: page ?? 1
        } }).then(
            res => {
                // detect if we get an array of parcours or a single parcours
                this.currentParcours = res.data[0]
                this.uniqueParcours = true
                
                this.fetching = false
            },
            error => {
                this.fetching = false

                if (error.response.status == 401) {
                    this.refreshToken(() => {
                        this.fetchParcours(page)
                    })
                    
                    return
                }
            }
        )
    },
  }
}
</script>