<template>
    <div class="banner">
        <div class="banner__locked p-4  flex justify-center items-center rounded-2xl" v-if="item.locked">
            <img src="@/assets/icons/icon-lock.svg" alt="">
        </div>
        <div class="banner__content flex items-center">
            <div class="banner__content__bloc flex items-center rounded-tl-2xl rounded-bl-2xl">
                <img src="@/assets/to-do-list.png" alt="">
                <div class="banner__header relative px-4 rounded-tl-2xl rounded-bl-2xl">
                    <h2 v-html="item.name.replace(/\n/g, '<br />')"/>
                </div>
            </div>
            <div class="banner__cta flex items-center justify-center rounded-tr-2xl rounded-br-2xl">
                <button @click="launcher(item.ref)" class="btn btn-start rounded-full rounded-full py-2 px-4 flex items-center inline-flex">
                    Répondre
                </button>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import AuthMixin from '@/mixins/authMixin'
  import UIMixin from '@/mixins/uiMixin'
  
  export default {
    mixins: [AuthMixin, UIMixin],
    props: {
      item: Object,
      themeStore: Object
    },
    data() {
      return {
        fetchingUrl: false,
      }
    },
    methods: {
        launcher(scormCloudId) {
            if (this.fetchingUrl || this.item.locked) {
                return false
            }
            
            const client = this.themeStore.client
            const url = process.env.VUE_APP_BASE_URL + '/data/Scorm/' + scormCloudId
            + '/link?client=' + client
            const accessToken = this.$cookies.get('access_token')
            this.fetchingUrl = true

            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: 'Bearer ' + accessToken
            }

            this.$http.get(url, { headers: headers }).then(
                res => {
                    const url = res.data

                    if (this.deviceType == 'desktop') {
                        window.open(url)
                    } else {
                        window.location.href = url
                    }

                    this.fetchingUrl = false
                },
                error => {
                    this.fetchingUrl = false

                    if (error.response.status == 401) {
                        this.refreshToken(() => {
                            this.launcher(scormCloudId)
                        })
                        
                        return
                    }
                }
            )
        }
    }
  }
  </script>